.a-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 180px;
  &, & * {
    transition: border .2s, color .2s, background .2s;
    @include font_btn(20,30,700);
    @include _1170 {
      @include font_btn(16,25);
      @include _979 {
        @include font_btn(13,19);
      }
    }
  }
  height: 68px;
  @include _1170 {
    height: 52px;
    @include _979 {
      height: 42px;
    }
  }
  & svg {
    transition: fill .2s;
  }
  &-outline {
    background: $white;
    border: 1px solid $inactiveRed;
    color: $inactiveRed;
    &:hover {
      background: $red2;
      border: 1px solid $red2;
      color: $white;
    }
    &_reversed {
      border: 1px solid $white;
      color: $white;
      background: transparent;
      &:hover {
        background: $white;
        color: $inactiveRed;
        border: 1px solid $inactiveRed;
      }
    }

  }
  &-red {
    background: $red;
    border: 1px solid $red;

  }
  &-red2 {
    background: $red2;
    border: 1px solid $red2;
  }
  &-red, &-red2 {
    color: $white;
    &:hover {
      background: $white;
      color: $inactiveRed;
      border: 1px solid $inactiveRed;
    }
  }
  &-black {
    color: white;
    background: $text;
    border: 1px solid $text;
    &:hover {
      color: black;
      background: white;
    }
  }
  &.disabled {
    border: 1px solid $inactiveBtn;
    background: $inactiveBtn;
    color: $white;
    pointer-events: none;
  }
}

.button-to-top{
  position: fixed;
  right: clampFluid(5, 60);
  bottom: 40vh;
  height: clampFluid(156);
  width: clampFluid(68);
  background-color: #C92323;
  border: 1px solid #C92323;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  transition: all .3s ease;
  transform: translateX(calc(100% + 60px));
  &.active{
    transform: none;
  }
  &:hover{
    background-color: #fff;
    svg{
      fill: #C92323;
    }
    span{
      color: #C92323;
    }
  }
  &__wrapper{
    rotate: -90deg;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clampFluid(14);
  }
  svg{
    width: clampFluid(19);
    height: clampFluid(19);
    rotate: 90deg;
    fill: #fff;
    transition: all .3s ease;
  }
  span{
    font-family: Source Sans Pro;
    font-size: clampFluid(14, 20);
    font-weight: 700;
    line-height: 120%;
    color: #fff; 
    transition: all .3s ease;   
  }
}