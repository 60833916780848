@import "./functions";
@import "./media";
@mixin defaultWrapper {
  width: 1620px;
  @include _1700 {
    width: 1440px;
    @include _1500 {
      width: 1290px;
      @include _1320 {
        width: 1170px;
        padding: 0 15px;
        @include _1170 {
          width: 100%;
        }
      }
    }
  }
}
@mixin font($size, $height:null, $weight:null, $color:null) {
  font-size: #{$size}px;
  @if($height) {
    line-height: #{$height}px;
  }
  @if($weight) {
    font-weight: #{$weight};
  }
  @if($color) {
    color: #{$color};
  } @else {
    color: $text;
  }

}

@mixin font_btn($size, $height:null, $weight:null, $color:null) {
  font-size: #{$size}px;
  @if($height) {
    line-height: #{$height}px;
  }
  @if($weight) {
    font-weight: #{$weight};
  }
  @if($color) {
    color: #{$color};
  }
}