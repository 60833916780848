@function clampFluid($clamp-min, $clamp-max: 'None', $calc-1: 'None', $calc-2: 'None', $w-min: 'None', $w-max: 'None'){
  @if $clamp-max == 'None'{
      $clamp-max: $clamp-min;
      $clamp-min: $clamp-max * 2 / 3;
  };
  $clamp-min-rend: 'None';
  $clamp-max-rend: 'None';

  $w: $clamp-max/1920 * 100 * 1vw;

  @if $clamp-min == 0{
      $clamp-min: 0.001;
  }

  @if $clamp-max == 0{
      $clamp-max: 0.001;
  }

  @if $clamp-max < $clamp-min{
      $clamp-min-rend: $clamp-max * 1px;
      $clamp-max-rend: $clamp-min * 1px;
  }
  @else {
      $clamp-max-rend: $clamp-max * 1px;
      $clamp-min-rend: $clamp-min * 1px;
  }
  
  @if $calc-1 == 'None'{
      @return clamp($clamp-min-rend, $w, $clamp-max-rend);
  };

  @if $w-min == 'None'{
      $k: ($clamp-max - $clamp-min)/($calc-2 - $calc-1);
      $b: $clamp-min - $k * $calc-1;
      $b: $b * 1px;
      @return clamp($clamp-min-rend, calc(#{$k} * 100vw + #{$b} ), $clamp-max-rend);
  };

  @if $w-max != 'None'{
      $k: ($calc-2 - $calc-1)/($w-max - $w-min);
      $b: $calc-1 - $k * $w-min;
      $b: $b * 1px;
      @return clamp($clamp-min-rend, calc(#{$k} * 100vw + #{$b} ), $clamp-max-rend);
  }
}

@function calc_fluid($min-v, $max-v, $min-w, $max-w) {
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc(#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m});
}
@function calc_fluid_vw($min-v, $max-v, $min-w, $max-w) {
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc((100vw - (100vw - calc(#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m}))) / 2);
}
@function calc_fluid_static($min-v, $max-v, $min-w, $max-w, $static) {
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc((#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m}) + #{$static}px);
}

@function mega_super_calc_fluid_for_departments_slider_arrows_resize($min-v, $max-v, $min-w, $max-w) {
  $fluid: calc_fluid($min-v, $max-v, $min-w, $max-w);
  @return calc(100% - (#{$fluid} * 2) + 20px);
}

@function auto_fluid($max-v, $min-w, $max-w) {
  $min-v: $min-w / 100 * ($max-v / ($max-w / 100));
  $scale-w: $max-w - $min-w;
  $scale-v: $max-v - $min-v;
  $scale-m: $scale-v / $scale-w;
  @return calc(#{$min-v}px + (100vw - #{$min-w}px) * #{$scale-m});
}


@function calc_static($value, $value2, $new_value) {
  $ratio-operand: $value2 / ($value / 100);

  @return $new_value / 100 * $ratio-operand;
}