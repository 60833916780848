.breadcrumbs {
  margin: 25px 0 calc_fluid(20,40,768,1920);
  @include _768 {
    margin: 25px 0 20px;
  }

  display: flex;
  flex-flow: row wrap;
  width: 100%;
  &-item {
    position: relative;
    margin-left: calc_fluid(17,23,320,1920);
    &:first-child {
      margin-left: 0;
    }
    &__text {
      font-size: calc_fluid(13,15,320,1920);
      line-height: calc_fluid(19,22,320,1920);
    }
    &__current {
      color: $inactiveText;
    }
    &__link {
      color: $text;
      &:hover {
        color: $red2;
      }
    }
    $itm: &;
    &:not(:first-child):before {
      font-family: 'Noto Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      position: absolute;
      left: calc_fluid(-15,-17,320,1920);
      content: "—";
      color: $text;
    }
  }
  $bc: &;
  &.typical2 {
    #{$bc} {
      &-item {
        &__current, &__link {
          color: $white;
        }
        &:not(:first-child):before {
          color: $white;
        }
      }

    }
  }
}