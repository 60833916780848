

$blk: #333;
$text: $blk;
$red: #E11408;
$red2: #C92323;
$green: #4FAD50;
$white: #fff;
$blu: #0054A6;
$b-blu: #00407F;

$footerBottomText: #A4A4A4;


$bgCD: #CDCDCD;
$bgF9: #F9F9F9;

$inactiveText: #696969;
$inactive87: #878787;
$inactiveRed: #5A4C66;
$inactiveBtn: #BDBAC0;
$bgD1: #D1D1D1;
$borderAD: #ADADAD;
$border5F: #5F5F5F;
$borderRegistration: #5A4C66;
$border46: #464646;
$inactiveBg: #F9F9F9;
$borderCD: #CDCDCD;
$borderED: #EDEDED;
$borderA4: #A4A4A4;
$borderE2: #E2E2E2;
$breadcrumbsBefore: #9AA7BC;
$disabled: #BCBCBC;
$disabled1: #94B7DA;