@import "../env";
@import "../components/base/breadcrumbs";
@import "../components/base/buttons";

.page {
  width: 100%;
  display: flex;
  justify-content: center;

  &-wrapper {
    @include defaultWrapper;
    padding: 0 15px;
  }
}
.nf {
  position: relative;
  height: 374px;
  @include _1600 {
    padding-bottom: 100px;
    height: unset;
    @include _1440 {
      padding-bottom: 80px;
      @include _1170 {
        padding-bottom: 50px;
        @include _768 {
          padding-bottom: 40px;
        }
      }
    }
  }
  &-bottom {
    margin-top: calc_fluid(20,34,320,1920);
    display: flex;
    &__back {
      width: 318px;
    }
    &__callback {
      margin-left: 22px;
      width: 270px;
    }
    @include _600 {
      flex-flow: row wrap;
      &__back {
        width: 100%;
      }
      &__callback {
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }
  &__title {
    text-align: unset;
  }
  &__description {
    margin-top: 20px;
    @include font(20,30);
    @include _1440 {
      margin-top: 18px;
      @include font_btn(14,20);
    }
    @include _979 {
      margin-top: 15px;
      @include font_btn(13,19);
    }
    max-width: 688px;
  }
  &__banner {
    position: absolute;
    width: 1053px;
    height: 702px;
    right: 47px;
    top: 12px;
    object-fit: cover;
    z-index: -1;
  }
}