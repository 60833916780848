@charset "UTF-8";
.breadcrumbs {
  margin: 25px 0 calc(20px + (100vw - 768px) * 0.01736);
  display: flex;
  flex-flow: row wrap;
  width: 100%; }
  @media (max-width: 768px) {
    .breadcrumbs {
      margin: 25px 0 20px; } }
  .breadcrumbs-item {
    position: relative;
    margin-left: calc(17px + (100vw - 320px) * 0.00375); }
    .breadcrumbs-item:first-child {
      margin-left: 0; }
    .breadcrumbs-item__text {
      font-size: calc(13px + (100vw - 320px) * 0.00125);
      line-height: calc(19px + (100vw - 320px) * 0.00187); }
    .breadcrumbs-item__current {
      color: #696969; }
    .breadcrumbs-item__link {
      color: #333; }
      .breadcrumbs-item__link:hover {
        color: #C92323; }
    .breadcrumbs-item:not(:first-child):before {
      font-family: 'Noto Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      position: absolute;
      left: calc(-15px + (100vw - 320px) * -0.00125);
      content: "—";
      color: #333; }
  .breadcrumbs.typical2 .breadcrumbs-item__current, .breadcrumbs.typical2 .breadcrumbs-item__link {
    color: #fff; }
  .breadcrumbs.typical2 .breadcrumbs-item:not(:first-child):before {
    color: #fff; }

.a-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 180px;
  height: 68px; }
  .a-btn, .a-btn * {
    transition: border .2s, color .2s, background .2s;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700; }
    @media (max-width: 1170px) {
      .a-btn, .a-btn * {
        font-size: 16px;
        line-height: 25px; } }
  @media (max-width: 1170px) and (max-width: 979px) {
    .a-btn, .a-btn * {
      font-size: 13px;
      line-height: 19px; } }
  @media (max-width: 1170px) {
    .a-btn {
      height: 52px; } }
  @media (max-width: 1170px) and (max-width: 979px) {
    .a-btn {
      height: 42px; } }
  .a-btn svg {
    transition: fill .2s; }
  .a-btn-outline {
    background: #fff;
    border: 1px solid #5A4C66;
    color: #5A4C66; }
    .a-btn-outline:hover {
      background: #C92323;
      border: 1px solid #C92323;
      color: #fff; }
    .a-btn-outline_reversed {
      border: 1px solid #fff;
      color: #fff;
      background: transparent; }
      .a-btn-outline_reversed:hover {
        background: #fff;
        color: #5A4C66;
        border: 1px solid #5A4C66; }
  .a-btn-red {
    background: #E11408;
    border: 1px solid #E11408; }
  .a-btn-red2 {
    background: #C92323;
    border: 1px solid #C92323; }
  .a-btn-red, .a-btn-red2 {
    color: #fff; }
    .a-btn-red:hover, .a-btn-red2:hover {
      background: #fff;
      color: #5A4C66;
      border: 1px solid #5A4C66; }
  .a-btn-black {
    color: white;
    background: #333;
    border: 1px solid #333; }
    .a-btn-black:hover {
      color: black;
      background: white; }
  .a-btn.disabled {
    border: 1px solid #BDBAC0;
    background: #BDBAC0;
    color: #fff;
    pointer-events: none; }

.button-to-top {
  position: fixed;
  right: clamp(5px, 3.125vw, 60px);
  bottom: 40vh;
  height: clamp(104px, 8.125vw, 156px);
  width: clamp(45.33333px, 3.54167vw, 68px);
  background-color: #C92323;
  border: 1px solid #C92323;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  transition: all .3s ease;
  transform: translateX(calc(100% + 60px)); }
  .button-to-top.active {
    transform: none; }
  .button-to-top:hover {
    background-color: #fff; }
    .button-to-top:hover svg {
      fill: #C92323; }
    .button-to-top:hover span {
      color: #C92323; }
  .button-to-top__wrapper {
    rotate: -90deg;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(9.33333px, 0.72917vw, 14px); }
  .button-to-top svg {
    width: clamp(12.66667px, 0.98958vw, 19px);
    height: clamp(12.66667px, 0.98958vw, 19px);
    rotate: 90deg;
    fill: #fff;
    transition: all .3s ease; }
  .button-to-top span {
    font-family: Source Sans Pro;
    font-size: clamp(14px, 1.04167vw, 20px);
    font-weight: 700;
    line-height: 120%;
    color: #fff;
    transition: all .3s ease; }

.page {
  width: 100%;
  display: flex;
  justify-content: center; }
  .page-wrapper {
    width: 1620px;
    padding: 0 15px; }
    @media (max-width: 1700px) {
      .page-wrapper {
        width: 1440px; } }
  @media (max-width: 1700px) and (max-width: 1500px) {
    .page-wrapper {
      width: 1290px; } }
  @media (max-width: 1700px) and (max-width: 1500px) and (max-width: 1320px) {
    .page-wrapper {
      width: 1170px;
      padding: 0 15px; } }
  @media (max-width: 1700px) and (max-width: 1500px) and (max-width: 1320px) and (max-width: 1170px) {
    .page-wrapper {
      width: 100%; } }

.nf {
  position: relative;
  height: 374px; }
  @media (max-width: 1600px) {
    .nf {
      padding-bottom: 100px;
      height: unset; } }
  @media (max-width: 1600px) and (max-width: 1440px) {
    .nf {
      padding-bottom: 80px; } }
  @media (max-width: 1600px) and (max-width: 1440px) and (max-width: 1170px) {
    .nf {
      padding-bottom: 50px; } }
  @media (max-width: 1600px) and (max-width: 1440px) and (max-width: 1170px) and (max-width: 768px) {
    .nf {
      padding-bottom: 40px; } }
  .nf-bottom {
    margin-top: calc(20px + (100vw - 320px) * 0.00875);
    display: flex; }
    .nf-bottom__back {
      width: 318px; }
    .nf-bottom__callback {
      margin-left: 22px;
      width: 270px; }
    @media (max-width: 600px) {
      .nf-bottom {
        flex-flow: row wrap; }
        .nf-bottom__back {
          width: 100%; }
        .nf-bottom__callback {
          width: 100%;
          margin-top: 15px;
          margin-left: 0; } }
  .nf__title {
    text-align: unset; }
  .nf__description {
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
    color: #333;
    max-width: 688px; }
    @media (max-width: 1440px) {
      .nf__description {
        margin-top: 18px;
        font-size: 14px;
        line-height: 20px; } }
    @media (max-width: 979px) {
      .nf__description {
        margin-top: 15px;
        font-size: 13px;
        line-height: 19px; } }
  .nf__banner {
    position: absolute;
    width: 1053px;
    height: 702px;
    right: 47px;
    top: 12px;
    object-fit: cover;
    z-index: -1; }
